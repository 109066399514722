// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // live
  
  SOCKET_ENDPOINT: 'https://paisaenapi.paisaexch.com/',
  adminServerUrl: "https://rnapi.paisaexch.com/api/",
  bwtServerUrl: "https://acepunt.kushubmedia.com/user/",
  walleturl:"https://adminapi.paisaexch.com/wallet/",
  // bwtstagingServerUrl: "https://178.62.77.178:4005/user/",


  // staging

  // SOCKET_ENDPOINT: 'http://142.93.40.145:3096/',
  // adminServerUrl: "http://142.93.40.145:3099/api/",


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
