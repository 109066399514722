<div id="site_footer-settings" class="site_footer customfootbar">
  <div class="site_footer_inn">
    <ul class="nav-bottom-site site-ui-inn">
      <li>
      
        <a *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/dashboard/inplay']" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <i class="material-icons">play_circle_filled</i> 
          <span class="name-style">Inplay</span>
        </a> 
      </li>
      <li>
        <a  *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/dashboard']" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <i class="material-icons">maps_home_work</i>
          <span class="name-style">Home</span>
        </a>
      </li>
      
      <!-- <li class="display-class chaticon" >
        <div  class="chaport-launcher-button no-photo chaport-launcher-anim chaport-anim-show"  [routerLink]="['/wallet']" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">

          <i class="material-icons active" >account_balance_wallet</i>
      </div>
      </li> -->

      <!-- <li>
        <a *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/casino']"  [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <i class="material-icons">casino</i>
          <span class="name-style">Casinos</span>
        </a>
      </li> -->
    
    
      <li  class="hide-specific">
        <a *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/wallet']"
        [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
        <i class="material-icons">account_balance_wallet</i>
          <span class="name-style">Wallet</span>
        </a>
      </li>
      <li>
        <a  *ngIf="userDetails?.details?.manager!='OSG365'" (click)="openModalResetPwd(changePwd)"  [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <i class="material-icons">mobile_screen_share</i> 
          <span class="name-style">Referral</span>
        </a>
      </li>
      <li  class="hide-specific">
        <a *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/wallet-passbook']"
        [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
        <i class="material-icons">sticky_note_2</i>
          <span class="name-style">Passbook</span>
        </a>
      </li>
      
      <li>
        <a  *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/user-profile']"   [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <i class="material-icons">person</i> 
          <span class="name-style">Profile</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="mobile-bottom-bar" hidden>
  <div class="bottom-menu bottom-menu contianermobile">
    <mat-list>
      <mat-list-item *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/dashboard/inplay']"
        [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
        <i class="material-icons">ondemand_video</i>
        <span>Inplay</span></mat-list-item>
      <mat-list-item [routerLink]="['/dashboard/']" [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['active']">
        <i class="material-icons">home</i> <span>Dashboard</span></mat-list-item>

      <mat-list-item *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/casino']"
        [routerLinkActive]="['active']">
        <i class="material-icons">casino</i> <span>Casino</span>
      </mat-list-item>

      <!-- <mat-list-item
        *ngIf="avaialableEventType!='-1' && ListData?.length!=0 && userDetails?.details?.manager!='OSG365'"
        class="blink_me" (click)="set_fantacy_model()" [routerLink]="['/fixed-deposit']"
        [routerLinkActive]="['active']">
        <i class="material-icons">spoke</i> <span>Investment</span></mat-list-item> -->

      <mat-list-item [routerLink]="['/wallet']" [routerLinkActive]="['active']">
        <i class="material-icons">account_balance_wallet</i>
        <span>Wallet</span></mat-list-item>

      <mat-list-item (click)="sidenavRight.toggle()" [routerLinkActive]="['active']">
        <i class="material-icons">person</i><span>Profile</span></mat-list-item>
    </mat-list>
  </div>
</div>

<mat-sidenav mode="side" #sidenavRight closed position="end" (click)="sidenavRight.toggle()" class="right-panel">
  <!-- <div class="sidebar-first-heading">
        <span><mat-icon>close</mat-icon></span>
        <button mat-stroked-button color="warn" (click)="logoutUser()">Logout <mat-icon>logout</mat-icon></button>
    </div> -->
  <div class="row user-profile align-items-center">
    <div class="col-xs-3">
      <img src="assets/images/user-circle.svg" />
    </div>
    <div class="col-xs-3 username">
      {{ userDetails?.details?.username }}
      <div class="last-login">
        <div>MobileNo : </div>&nbsp;
                <div>{{userDetails?.details?.mobile}}</div>
        <!-- <div>Last login</div>
                <div>{{moment(userDetails?.details?.updatedAt).utcOffset("+05:30").format('lll')}}</div> -->
      </div>
    </div>
    <!-- <div class="sidebar-first-heading">
      <button *ngIf="tokenStatus" mat-stroked-button color="warn" (click)="logoutUser()">
        <mat-icon>logout</mat-icon>
      </button>
    </div> -->
  </div>
  <!-- <div class="row" style="margin: 5px 10px">
    <div class="winning-card">
      <a style="color: #ffffff">
        <img src="assets/icons/account_balance.png" />
        <div>Balance</div>
        <h5>
          <b>{{ walletBalance | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <div class="winning-card" *ngIf="userDetails?.details?.manager!='OSG365'">
      <a style="color: #ffffff">
        <i class="material-icons">casino</i>

        <div>Casino</div>
        <h5>
          <b>{{ casinoBal | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <div class="winning-card" *ngIf="userDetails?.details?.manager!='OSG365' && rollingBalance?.rolling_status==1">
      <a style="color: #ffffff">
        <i class="material-icons md-dark">credit_card</i>
        
        <div>Rolling</div>
        <h5>
          <b>{{ rollingBalance?.rolling_amount | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <div class="exposure-card">
      <img src="assets/icons/exposure.png" />
      <div>Exposure</div>
      <h5>
        <b>{{ exposure | number : "0.0-2" }}</b>
      </h5>
    </div>
  </div> -->



  <div class="report-menu">
    <!-- <h4>Reports</h4> -->
    <ul class="menu-list">
      <li>
        <a class="dropdown-item" [routerLink]="['/dashboard']"><img src="assets/images/icon/home.png" /> Home</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/ledger']"><img src="assets/images/icon/open_bet.png" /> Ledger</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/offer-ledger']"><img src="assets/images/icon/open_bet.png" />Offer Ledger</a>
      </li>
      <!-- <li *ngIf="avaialableEventType!='-1' && ListData?.length!=0 && userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" (click)="set_fantacy_model()" [routerLink]="['/fixed-deposit']"
        [routerLinkActive]="['active']">
        <i class="material-icons">spoke</i>&nbsp; Investment </a></li>


      <li *ngIf="userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/casino-wallet']"><img src="assets/images/wallet.svg" /> Casino
          Wallet</a>
      </li>
      <li *ngIf="userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/referAcc']"><img src="assets/images/wallet.svg" /> Refer Account</a>
      </li>
      <li *ngIf="userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/referal-report']"><img src="assets/images/wallet.svg" /> Referal
          Report</a>
      </li>
      <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/fixed-deposit']"><img src="assets/images/wallet.svg" /> Fixed
          Deposit</a>
      </li>
      <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/fixed-deposit-report']"><img src="assets/images/wallet.svg" /> Fixed
          Deposit Report</a>
      </li> -->
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/referAcc']"
          ><img src="assets/images/wallet.svg" /> Refer Account</a
        >
      </li> -->
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/profile']"><img src="assets/images/icon/user.svg" /> Profile</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/terms-condition']"><img src="assets/images/icon/report.svg" /> Terms &
          Conditions</a>
      </li>
      <li>
        <a class="dropdown-item" (click)="openModalResetPwd(changePwd)"><img
            src="assets/images/icon/change_stack.png" /> Change Password</a>
      </li> -->
      <li *ngIf="tokenStatus" (click)="logoutUser()">
        <a style="color: red !important"> <mat-icon>logout</mat-icon></a> LogOut
      </li>
    </ul>
  </div>
</mat-sidenav>


<ng-template #changePwd  class="openpad">
  <div matDialogTitle  class="backcolorGift">
    <img  class="logimag" src="assets/logo/localhost.png" alt="img">
<span> <i  (click)="modalRef.hide()" class="material-icons cnspo">cancel</i></span>
  </div>
  <div class="comingsoon">
    <span>
      Comming Soon !
    </span>
      </div>
<!-- <div class="offertextdiv">
 <p class="headgift">Refer and earn</p> 
<p class="testop">Be our brand hero, refer your friend using your refer code, you get 500 on your 
  friend’s first deposit and your friend gets 1500 bonus after registration.</p>
  <div class="refretext">
    <span>
      {{promo}}
    </span>
    <button class="copytest" (click)="clipBoard(promo)">Copy</button>
  </div>
  <div  class="sharing"><div  class="icon-list">
    <div class="icon-item ng-star-inserted">
      <div  class="icon-wrap">
      <a href="https://api.whatsapp.com/send?text=Hey%20Friend%2C%20look%20what%20i%20have%20for%20you%20found%20the%20best%20sports%20and%20casino%20betting%20site%20and%20i%20am%20introducing%20the%20same%20to%20you%20Its%20called%20telegrambook.com.%20Signup%20now%20using%20my%20refer%20code%20{{promo}}%20and%20you%20get%201500%20welcome%20bonus%20in%20your%20bonus%20wallet.%20I%20got%20my%20first%20withdrawal%20in%20less%20than%202%20minutes%2C%20why%20don%E2%80%99t%20you%20give%20it%20a%20try!"><img  onerror="src = '../assets/img/whatsapp-icon.svg'" style="width:30px" alt="WhatsApp" src="https://ss.manage63.com/bmk-wl/commonAssets/whatsapp-icon.svg"></a>
      </div>
      <p class="testop">WhatsApp</p>
    </div>
    <div  class="icon-item ng-star-inserted">
      <div  class="icon-wrap">
       <a href="https://api.whatsapp.com/send?text=Hey%20Friend%2C%20look%20what%20i%20have%20for%20you%20found%20the%20best%20sports%20and%20casino%20betting%20site%20and%20i%20am%20introducing%20the%20same%20to%20you%20Its%20called%20telegrambook.com.%20Signup%20now%20using%20my%20refer%20code%20{{promo}}%20and%20you%20get%201500%20welcome%20bonus%20in%20your%20bonus%20wallet.%20I%20got%20my%20first%20withdrawal%20in%20less%20than%202%20minutes%2C%20why%20don%E2%80%99t%20you%20give%20it%20a%20try!">  <img  onerror="src = '../assets/img/wa_business.svg'"  alt="WhatsApp" src="https://ss.manage63.com/bmk-wl/commonAssets/wa_business.svg"></a>
      </div>
      <p class="testop">WA Business</p>
    </div>
    <div  class="icon-item ng-star-inserted">
      <div  class="icon-wrap">
        <a href="https://telegram.org/send?text=Hey%20Friend%2C%20look%20what%20i%20have%20for%20you%20found%20the%20best%20sports%20and%20casino%20betting%20site%20and%20i%20am%20introducing%20the%20same%20to%20you%20Its%20called%20telegrambook.com.%20Signup%20now%20using%20my%20refer%20code%20{{promo}}%20and%20you%20get%201500%20welcome%20bonus%20in%20your%20bonus%20wallet.%20I%20got%20my%20first%20withdrawal%20in%20less%20than%202%20minutes%2C%20why%20don%E2%80%99t%20you%20give%20it%20a%20try!"><img  onerror="src = '../assets/img/telegram.svg'" alt="WhatsApp" src="https://ss.manage63.com/bmk-wl/commonAssets/telegram.svg"></a>
      </div><p class="testop">Telegram</p>
    </div>
    <div  class="icon-item">
      <div  class="icon-wrap more" (click)="coptext()">
        <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">content_copy</mat-icon></div>
        <p class="testop">Copy Text</p></div></div></div>
</div> -->
</ng-template>
