import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from "../services/users.service";

@Component({
  selector: 'app-login-with-otp',
  templateUrl: './login-with-otp.component.html',
  styleUrls: ['./login-with-otp.component.scss']
})
export class LoginWithOtpComponent implements OnInit,OnDestroy{
  loginForm: FormGroup;
  varifyOtpForm: FormGroup;
  loginButtonDisable=false;
  otpButtonDisable=false;
  submitted = false;
  private clickTimeout:any =null;
  public showPassword:boolean;
  a:any;
  logo: string;
  step:number=1;
  countdown: number;
  userdetail:any;
  showCountdown: boolean = false;
  constructor(private router: Router, public httpClient: UsersService,private fb: FormBuilder,private toastr: ToastrService,private socket: Socket) { }

  ngOnInit(): void {
    this.changeIcon();
    this.createFrom();
  }

  async findHostName()
    { 
      return (window.location.hostname);
    }

  async changeIcon() {
     const hostname=await this.findHostName();
     const splithostname= hostname.split('.')
     this.logo = splithostname[0];
     this.setManager();
    
  }

  createFrom() {
    this.loginForm = this.fb.group({
      phone: ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
      manager: ''
    })

    this.varifyOtpForm = this.fb.group({
      phone: [''],
      otp: ['', [Validators.required]],
      manager: ''
    })
    
  }

  setManager(){
    if(this.logo==='clubprt')
    {
      this.loginForm.patchValue({manager: 'PRTCLUB'});
      this.varifyOtpForm.patchValue({manager: 'PRTCLUB'});
    }else if(this.logo==='ferrariclubb'){
      this.loginForm.patchValue({manager: 'FERRARICLUB'});
      this.varifyOtpForm.patchValue({manager: 'FERRARICLUB'});
    }else if(this.logo==='boomboombook'){
      this.loginForm.patchValue({manager: 'BOOM'});
      this.varifyOtpForm.patchValue({manager: 'BOOM'});
    }
    else if(this.logo==='clubaob'){
      this.loginForm.patchValue({manager: 'AOBCLUB'});
      this.varifyOtpForm.patchValue({manager: 'AOBCLUB'});
    }
    else if(this.logo==='dlexch'){
      this.loginForm.patchValue({manager: 'DLCLUB'});
      this.varifyOtpForm.patchValue({manager: 'DLCLUB'});
    }
    else if(this.logo==='fairbets247'){
      this.loginForm.patchValue({manager: 'FAIRBET'});
      this.varifyOtpForm.patchValue({manager: 'FAIRBET'});
    }
    else if(this.logo==='paisaexch'||this.logo==='clubosg'||this.logo==='dubaiclub247'){
      this.loginForm.patchValue({manager: 'OSGCLUB'});
      this.varifyOtpForm.patchValue({manager: 'OSGCLUB'});
    }
    else if(this.logo==='clublucky7'){
      this.loginForm.patchValue({manager: 'PRTLUCKY'});
      this.varifyOtpForm.patchValue({manager: 'PRTLUCKY'});
    }
    else if(this.logo==='telegrambook'){
      this.loginForm.patchValue({manager: 'TELE'});
      this.varifyOtpForm.patchValue({manager: 'TELE'});
    }
    else{
      this.loginForm.patchValue({manager: 'TELE'});
      this.varifyOtpForm.patchValue({manager: 'TELE'});

    }
  }

  async onLoginClick() {
    this.loginButtonDisable=true;
    if (this.clickTimeout) {
      this.setClickTimeout(() => {});
    } else {
      this.setClickTimeout(() =>  
      this.handleSingleLoginClick());
    }

   }

   async onVerifyOtpClick() {
    this.otpButtonDisable=true;
    if (this.clickTimeout) {
      this.setClickTimeout(() => {});
    } else {
      this.setClickTimeout(() =>  
         this.handleSingleVerifyClick());
    }

   }

   resendotp(){
    this.startCountdown();
    
    var data = {phone: this.loginForm.value.phone, manager:this.loginForm.value.manager }
    this.httpClient
      .walletPost("resendOtp", data)
      .subscribe((response: any) => {
        if(response.success){
          this.toastr.success(response.message, "Success!");
        }
        else{

        }
       
      });
    //this.socket.emit('reset-otp',data);
   }

   public handleSingleLoginClick() {
    //The actual action that should be performed on click      
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.loginButtonDisable=false;
      return;
    }

    this.varifyOtpForm.patchValue({phone: this.loginForm.value.phone});
    const loginData={user:this.loginForm.value}
   // console.log("fhfhfh",loginData);
    this.socket.emit('login-otp',loginData);
    this.socket.on('loginotp-success',(function(data:any){
      this.startCountdown();
     const output = data.message;
     if(output){
       this.toastr.success(data.message, 'Success!');
       this.step=2;
       this.loginButtonDisable=false
     } 
   }).bind(this));

  this.socket.on('login-error',(function(o:any){
    this.toastr.error(o.message);
    this.submitted = false;
    this.loginButtonDisable=false;
    this.otpButtonDisable=false;
  }).bind(this));
    
  }

  public handleSingleVerifyClick() {
    //The actual action that should be performed on click      
    this.submitted = true;
    if (this.varifyOtpForm.invalid) {
      this.otpButtonDisable=false;
      return;
    }

    const otpData={user:this.varifyOtpForm.value}
    this.socket.emit('new-login-verify-otp',otpData);
    
  this.socket.on('login-success',(function(data:any){
    this.toastr.success(data.message, "Login Success!");
    const output = data.output;
   // alert(output);
    console.log(output);
    //return
    if(output){
      sessionStorage.setItem('loginStatus','true');
      sessionStorage.setItem('userDetails',JSON.stringify(output));
      this.redirectToInplay();
    }
    
  }).bind(this));
    
  }

  startCountdown() {
    this.showCountdown = true;
    this.countdown = 120; // Initial countdown value
    this.doCountdown();
  }
  
  doCountdown() {
    setTimeout(() => {
      this.countdown--;
      if (this.countdown > 0) {
        this.doCountdown();
      } else {
        this.showCountdown = false;
      }
    }, 1000); // Update every second
  }
  public setClickTimeout(callback:any) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }

  async redirectToInplay()
  {
    var acctoken =  await sessionStorage.getItem( 'loginStatus' );

   if(acctoken != null && acctoken != undefined && acctoken != '')
   {
   
    this.router.navigate(['/dashboard']);
   }
  }

  ngOnDestroy() {
  //  this.socket.removeAllListeners();
  }
  
 
}
