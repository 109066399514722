import { Component, OnInit , TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-wallet-withdraw',
  templateUrl: './wallet-withdraw.component.html',
  styleUrls: ['./wallet-withdraw.component.scss']
})
export class WalletWithdrawComponent implements OnInit {
  withdrawForm: FormGroup;
  idwithdrawForm: FormGroup;
  loginButtonDisable = false;
  withdrawmethod: any;
  token: any;
  filter: any;
  type: any;
  withamount: any;
  setamount: any;
  createid: any;
  userdata: any;
  depositdata:any;
  userdatak:any;
  userwalletBalance:any;
  min_amount:any=500;
  modalRef: BsModalRef;
  logintokenscreen:any;
  type_id:any;
  type_s:any;
  types:any;
  typeIds:any;
  fildata: string;
  filData: any;
  
  constructor(
    private _location: Location,
    private usersService: UsersService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private socket: Socket,
  ) {
    this.route.paramMap.subscribe(param => {
      this.type = param.get('type') // (+)Converts string 'id' to number
    });

    var fildata = sessionStorage.getItem('fildata');
      this.filData=JSON.parse(fildata);
      this.userdatak =  JSON.parse(sessionStorage.getItem('userDetails'));
      console.log(this.filData);
      this.getUserBalance();
      this.getwithdral();
  }

   ngOnInit(): void {
    if (this.type == 'idwithdraw') {
      var getamount = sessionStorage.getItem('withdrawamount');
      this.withamount = JSON.parse(getamount);
      // console.log(this.withamount);
      this.setamount = this.withamount.coins;
      var data = sessionStorage.getItem('details');
      
      this.createid = JSON.parse(data);
    }
    this.token = sessionStorage.getItem("token")
    this.typeIds = sessionStorage.getItem("typeId")
    this.types = sessionStorage.getItem("type")
    //console.log(this.typeIds, this.types)
    var userdata = sessionStorage.getItem('wallet-user');
    this.userdata = JSON.parse(userdata);
    this.userdatak =  JSON.parse(sessionStorage.getItem('userDetails'));
    // this.userdatak = user.details;
     //console.log(this.userdatak)
    
    // console.log(this.userdata);
    this.createFrom();
    this.createidFrom();
    this.getwithdral();
   // this.getusertoken();
    this.getUserBalance();
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }

  getUserBalance() {
    const userdata = {
      user: {
        _id: this.userdatak._id,
        key: this.userdatak.key,
        token:this.userdatak.verifytoken,
        details: {
          username: this.userdatak.details.username,
          role: this.userdatak.details.role,
          status: this.userdatak.details.status,
        },
      }
    };
    
    this.socket.emit('get-user', userdata);
    
    this.socket.on('get-user-success',(function(data:any){
      if(data){
        this.userwalletBalance = data.balance;
        this.userwalletexposer = data.exposure;
        // console.log( this.userwalletexposer);
        
      }
     }).bind(this));
    
  }
  
  getwithdral() {
    const data={
      user_id:this.userdatak.details._id,
    }
    this.usersService.walletPost("withdraw-method-list", data).subscribe((response: any) => {

      this.withdrawmethod = response.data;
      
    })
  }
  radioChange(event, bank) {
    this.filter = bank;
    // console.log(this.filter);
    sessionStorage.setItem('fildata', JSON.stringify(bank));
  }

  openmodel(success: TemplateRef<any>) {
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  createFrom() {
    this.withdrawForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(this.filData.minLimit), Validators.max(this.filData.maxLimit)]],
      type: ['', [Validators.required]],
    })
  }
  createidFrom() {
    this.idwithdrawForm = this.fb.group({
      type: ['', [Validators.required]],
    })
  }
  withdrawamount() {
    if (!this.withdrawForm.value.amount) {
      this.toastr.error("Please Enter Amount");
      return;
    } else if (this.withdrawForm.value.amount < this.filData.minLimit) {
      this.toastr.error("amount can't be less Amount "+ this.filData.minLimit);
      return;
    }else if (this.withdrawForm.value.amount > this.filData.maxLimit) {
      this.toastr.error("amount can't be greater Amount "+ this.filData.maxLimit);
      return;
    }
     else if (!this.withdrawForm.value.type) {
      this.toastr.error("Please select Bank");
      return;
    } else {
      this.loginButtonDisable = true;
      var data = {
        amount: this.withdrawForm.value.amount,
      // type: this.withdrawForm.value.type,
        //managerId: this.typeIds,
       // managertype: this.types,
        paymentId: this.filter._id,
        user_id:this.userdatak.details._id,
      }
      this.usersService.walletPost("create-withdraw-request", data).subscribe((response: any) => {
        this.depositdata = response;
        if (response) {
          document.getElementById("openModalButton").click();
          this.withdrawForm?.reset();
          this.loginButtonDisable = false;
          // this.toastr.success(response.message);
        }
      });
    }
  }

  idwithdrawamount() {
    // if (this.setamount != '') {
    // } else {
    //   this.loginButtonDisable = false;
    // }
    if (!this.setamount) {
      this.toastr.error("Please Enter Amount");
      return;
    } else if (!this.idwithdrawForm.value.type) {
      this.toastr.error("Please select Bank");
      return;
    } else {
      this.loginButtonDisable = true;
      var data = {
        amount: this.setamount,
        user_id:this.userdatak.details._id,
        type: this.idwithdrawForm.value.type,
        managerId: this.createid.sites._id,
        managertype: this.createid.type,
        mysiteId: this.createid._id,
        paymentId: this.filter._id,
      }
      this.usersService.walletPost("withdrawalInsites",data).subscribe((response: any) => {
        this.depositdata = response;
        if (response) {
          document.getElementById("openModalButton").click();
          this.idwithdrawForm?.reset();
          this.loginButtonDisable = false;
          // this.toastr.success(response.message);
        }
      });
    }
  }
  godetail() {
    this.router.navigate(['wallet-withdraw-details']);
  }
  // ngOnDestroy() {
  //   sessionStorage.removeItem('withdrawamount');
  //   sessionStorage.removeItem('fildata');
  // }
}
